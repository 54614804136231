import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import LyneHomeCrossProductSlider from "../LyneHomeCrossProductSlider"

const LyneHomeCrossProductSection = ({ data, id, page }) => {
  return (
    <LyneHomeCrossProductSectionWrapper
      id={id}
      className={id ? "lyneup-page-section" : ""}
      page={page}
    >
      <Wrapper>
        <LyneHomeMaisonSectionTitle
          dangerouslySetInnerHTML={{
            __html: data.title,
          }}
          page={page}
        />
        <LyneHomeCrossProductSlider data={data.list} />
      </Wrapper>
    </LyneHomeCrossProductSectionWrapper>
  )
}

export const LyneHomeCrossProductSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
  padding: ${({ page }) => (page ? "35px 20px" : "40px 20px")};
  align-items: flex-start;
  text-align: center;

  @media (max-width: 550px) {
    min-height: auto;
    padding: ${({ page }) => (page ? "20px 20px" : "40px 20px")};
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  align-items: flex-start;
  text-align: center;
`

const LyneHomeMaisonSectionTitle = styled.div`
  font-size: ${({ page }) => (page ? "40px" : "32px")};
  line-height: ${({ page }) => (page ? "1" : "1.2")};
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-align: center;
  margin: 0px auto;
  margin-bottom: ${({ page }) => (page ? "5px" : "25px")};

  @media (max-width: 850px) {
    font-size: ${({ page }) => (page ? "40px" : "24px")};
  }

  @media (max-width: 550px) {
    text-align: left;
    margin-left: 0px;
  }
`

export default injectIntl(LyneHomeCrossProductSection)
