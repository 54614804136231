import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const HowToUseSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const HowToUseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 20px;

  @media (max-width: 600px) {
    padding: 0px;
    align-items: flex-start;
  }
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: ${({ index }) => (index == 0 ? "row" : "row-reverse")};
  width: 100%;
  position: relative;
  margin: 20px 0px;

  @media (max-width: 550px) {
    flex-direction: column;
    margin: 0px 0px;
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin: 0px 25px;
  align-items: center;

  @media (max-width: 550px) {
    flex-direction: column;
    margin: 0px;
  }
`

const SectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 850px) {
    font-size: 36px;
  }
`

const ImageBox = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;

  @media (max-width: 550px) {
    margin: 0px 0px 20px;
  }
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
  border-radius: 80px 0px;

  @media (max-width: 550px) {
    border-radius: 0px;
  }
`

const TextBox = styled.div`
  color: #262626;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 550px) {
    padding: 0px 20px;
  }
`
const Text = styled.div`
  font-family: "Gotham Book";
  color: #262626;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;

  p {
    margin-bottom: 0px;
  }

  a {
    text-decoration: underline;
  }
`

const HowToUseSection = ({ intl, data, id }) => {
  return (
    <HowToUseSectionWrapper id={id} className={id ? "lyneup-page-section" : ""}>
      <HowToUseContainer>
        {data.list.map((item, index) => {
          return (
            <RowWrapper index={index} key={index.toString()}>
              <ColumnWrapper>
                <ImageBox>
                  {item.image && (
                    <Image className="lazyload" data-src={item.image} />
                  )}
                  {item.mobile_image && (
                    <MobileImage
                      className="lazyload"
                      data-src={item.mobile_image}
                    />
                  )}
                </ImageBox>
              </ColumnWrapper>
              <ColumnWrapper>
                <TextBox>
                  {index == 0 && data.title && (
                    <SectionTitle
                      dangerouslySetInnerHTML={{
                        __html: data.title,
                      }}
                    />
                  )}
                  {item.text && (
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  )}
                  {item.second_text && (
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: item.second_text,
                      }}
                    />
                  )}
                </TextBox>
              </ColumnWrapper>
            </RowWrapper>
          )
        })}
      </HowToUseContainer>
    </HowToUseSectionWrapper>
  )
}

export default injectIntl(HowToUseSection)
